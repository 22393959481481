import { useIsScreen } from '@services/hooks.service';

import './credits.styl';

function Credits() {
  const isScreen = useIsScreen()

  if (isScreen) {
    return null;
  }

  return (
    <div className="credits">
      <a href="https://www.openstreetmap.org/copyright/" target="_blank" rel="noreferrer">
        © OpenStreetMap
      </a>
    </div>
  );
}

export default Credits;